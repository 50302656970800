import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
    /* eslint-disable @typescript-eslint/no-empty-interface */
    export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.input};
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};
    border-radius: 10px;
  }
  .ant-checkbox-inner{
    width: 20px !important;
    height: 20px !important;
    &::after{
        left: 5px !important;
    }
  }
  .ant-table-thead{
        .ant-checkbox-checked .ant-checkbox-inner:after{
            left: 5px !important;
        }
        .ant-checkbox-indeterminate .ant-checkbox-inner:after{
            left: 9px !important;
        }
  }
  .ant-pagination,.ant-table-pagination{
        display: flex;
        justify-content: center;
        gap: 8px;
        li{
            margin: 0 !important;
            border-radius: 12px !important;
            @media (max-width: 1024px) {
                border-radius: 6px !important;
            }
            border: 1px solid #E4E4E4;
        }
        .ant-pagination-item,.ant-pagination-next,.ant-pagination-prev{
            border: 1px solid #E4E4E4;
            width: 44px !important;
            height: 44px !important;
            display: flex;
            align-items: center !important;
            justify-content: center !important;
            @media (max-width: 1024px) {
                width: 33px !important;
                height: 33px !important;
            }
        }
        .ant-pagination-item:hover{
            background: #355DFF;
            background-color: #355DFF !important;
            border: 1px solid #355DFF;
            a{
                color: #ffff;
            }
        }
        .ant-pagination-item-active{
                background: #355DFF;
                border: 1px solid #355DFF;
            a{
                color: #ffff;
            }
        }
}

.globals__wrapper-scroll::-webkit-scrollbar-track {
    display: none;
}
.globals__wrapper-scroll::-webkit-scrollbar {
    width: 10px;
}
.globals__wrapper-scroll::-webkit-scrollbar-thumb {
    background: #355DFF;
    transition: all 0.4s ease-in-out;
}

.globals__wrapper-scroll {
    transition: 0.3s ease-in-out;
    overflow-y: overlay;
    overflow-x: hidden;
    place-content: start
}
`

export default GlobalStyle
