import BigNumber from 'bignumber.js'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { SLOW_INTERVAL } from 'config/constants'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import useSWRImmutable from 'swr/immutable'
import { BIG_ZERO } from 'utils/bigNumber'
import { bscTokens } from '@pancakeswap/tokens'
import { getTokenPrice, getTokenPriceWPi } from 'state/pools/helpers'
import { useBCakeProxyContractAddress } from 'views/Farms/hooks/useBCakeProxyContractAddress'
import { getMasterchefContract } from 'utils/contractHelpers'
import { useFastRefreshEffect } from 'hooks/useRefreshEffect'
import { featureFarmApiAtom, useFeatureFlag } from 'hooks/useFeatureFlag'
import { getFarmConfig } from '@pancakeswap/farms/constants'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync, fetchInitialFarmsData } from '.'
import { DeserializedFarm, DeserializedFarmsState, DeserializedFarmUserData, State } from '../types'
import {
    farmFromLpSymbolSelector,
    farmSelector,
    makeBusdPriceFromPidSelector,
    makeFarmFromPidSelector,
    makeLpTokenPriceFromLpSymbolSelector,
    makeUserFarmFromPidSelector,
} from './selectors'

export function useFarmsLength() {
    const { chainId } = useActiveWeb3React()
    return useSWRImmutable(chainId ? ['farmsLength', chainId] : null, async () => {
        const mc = getMasterchefContract(undefined, chainId)
        return (await mc.poolLength()).toNumber()
    })
}

export const usePollFarmsWithUserData = () => {
    const dispatch = useAppDispatch()
    const { account, chainId } = useActiveWeb3React()
    const {
        proxyAddress,
        proxyCreated,
        isLoading: isProxyContractLoading,
    } = useBCakeProxyContractAddress(account, chainId)
    const farmFlag = useFeatureFlag(featureFarmApiAtom)

    useSWRImmutable(
        chainId ? ['publicFarmData', chainId] : null,
        async () => {
            const farmsConfig = await getFarmConfig(chainId)
            const pids = farmsConfig.map((farmToFetch) => farmToFetch.pid)
            dispatch(fetchFarmsPublicDataAsync({ pids, chainId, flag: farmFlag }))
        },
        {
            refreshInterval: farmFlag === 'api' ? 50 * 1000 : SLOW_INTERVAL,
        },
    )
    // console.log('isProxyContractLoading', isProxyContractLoading)
    const name = proxyCreated
        ? ['farmsWithUserData', account, proxyAddress, chainId]
        : ['farmsWithUserData', account, chainId]

    useSWRImmutable(
        account && chainId ? name : null,
        async () => {
            const farmsConfig = await getFarmConfig(chainId)
            const pids = farmsConfig.map((farmToFetch) => farmToFetch.pid)
            const params = proxyCreated ? { account, pids, proxyAddress, chainId } : { account, pids, chainId }
            console.log('farms action is fetching, skipping here')
            dispatch(fetchFarmUserDataAsync(params))
        },
        {
            refreshInterval: SLOW_INTERVAL,
        },
    )
}

/**
 * Fetches the "core" farm data used globally
 * 2 = CAKE-BNB LP
 * 3 = BUSD-BNB LP
 */
const coreFarmPIDs = {
    56: [1, 2],
    97: [0, 1],
    5: [1, 2],
}

export const usePollCoreFarmData = () => {
    const dispatch = useAppDispatch()
    const { chainId } = useActiveWeb3React()
    const farmFlag = useFeatureFlag(featureFarmApiAtom)

    useEffect(() => {
        if (chainId) {
            dispatch(fetchInitialFarmsData({ chainId }))
        }
    }, [chainId, dispatch])

    useFastRefreshEffect(() => {
        if (chainId && farmFlag !== 'api') {
            dispatch(fetchFarmsPublicDataAsync({ pids: coreFarmPIDs[chainId], chainId, flag: farmFlag }))
        }
    }, [dispatch, chainId, farmFlag])
}

export const useFarms = (): DeserializedFarmsState => {
    const { chainId } = useActiveWeb3React()
    return useSelector(useMemo(() => farmSelector(chainId), [chainId]))
}

export const useFarmsPoolLength = (): number => {
    return useSelector((state: State) => state.farms.poolLength)
}

export const useFarmFromPid = (pid: number): DeserializedFarm => {
    const farmFromPid = useMemo(() => makeFarmFromPidSelector(pid), [pid])
    return useSelector(farmFromPid)
}

export const useFarmFromLpSymbol = (lpSymbol: string): DeserializedFarm => {
    const farmFromLpSymbol = useMemo(() => farmFromLpSymbolSelector(lpSymbol), [lpSymbol])
    return useSelector(farmFromLpSymbol)
}

export const useFarmUser = (pid): DeserializedFarmUserData => {
    const farmFromPidUser = useMemo(() => makeUserFarmFromPidSelector(pid), [pid])
    return useSelector(farmFromPidUser)
}

// Return the base token price for a farm, from a given pid
export const useBusdPriceFromPid = (pid: number): BigNumber => {
    const busdPriceFromPid = useMemo(() => makeBusdPriceFromPidSelector(pid), [pid])
    return useSelector(busdPriceFromPid)
}

export const useLpTokenPrice = (symbol: string) => {
    const lpTokenPriceFromLpSymbol = useMemo(() => makeLpTokenPriceFromLpSymbolSelector(symbol), [symbol])
    return useSelector(lpTokenPriceFromLpSymbol)
}

/**
 * @deprecated use the BUSD hook in /hooks
 */
export const usePriceRunBusd = ({ forceMainnet } = { forceMainnet: false }): BigNumber => {
    const price = useCakeBusdPrice({ forceMainnet })
    return useMemo(() => (price ? new BigNumber(price.toSignificant(6)) : BIG_ZERO), [price])
}

export const useTokenPrice = (token) => {
    const [price, setPrice] = useState(BIG_ZERO)
    useEffect(() => {
        const fetchTokenPrice = async () => {
            try {
                const tokenPriceObject = await getTokenPrice(token)
                setPrice(new BigNumber(tokenPriceObject?.usdPrice.toString()))
            } catch (error) {
                console.log(error)
            }
        }
        fetchTokenPrice()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return price
}

export const useTokenPriceWPi = () => {
    const [price, setPrice] = useState(BIG_ZERO)
    useEffect(() => {
        const fetchTokenPrice = async () => {
            try {
                const tokenPriceObject = await getTokenPrice(bscTokens?.wpi?.address)
                setPrice(new BigNumber(tokenPriceObject?.usdPriceFormatted?.toString()))
            } catch (error) {
                console.log(error)
            }
        }
        fetchTokenPrice()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return price
}
