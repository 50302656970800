import { createReducer } from '@reduxjs/toolkit'
import {fetchOrderApprove, fetchLoading } from './actions'
import { OrderApprove } from './type'

interface global {
    validateOrders: OrderApprove[],
    isLoading: boolean
}
export const initialState: global = {
    validateOrders: null,
    isLoading: false,
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchOrderApprove, (state, action) => {
            state.validateOrders = action.payload.validateOrders
        })
        .addCase(fetchLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
)