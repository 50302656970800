import { getStocksConfig } from 'config/constants/stocks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../index';
import { fetchDataStocks, fetchLoading, fetchOwnerMarketplace } from './actions';
import { getDataOwnerMarketplace, getDataStocks } from './fetchDataStocks';
import { getAddress } from 'utils/addressHelpers';
import contracts from 'config/constants/contracts';


export const GetDataHome = () => {
    const dataHome = useSelector<AppState, AppState['home']>((state) => state?.home)
    return dataHome
}

export const UseCoreManage = (chainId: number, account:string) => {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        (async () => {
            dispatch(fetchLoading({isLoading: !false}))
            const contractMarketplace = getAddress(contracts?.marketplace, chainId)
            
            const config = await getStocksConfig(chainId)
            
            const [ dataStocks, resultOwner] = await Promise.all([
                getDataStocks(config, chainId, account, contractMarketplace),
                getDataOwnerMarketplace(chainId, account, contractMarketplace)
            ])
            dispatch(fetchOwnerMarketplace(resultOwner))
            dispatch(fetchDataStocks(dataStocks))
            dispatch(fetchLoading({isLoading: false}))
        })();
    },[chainId, account])
};



export enum HomeTab {
    MANAGE = 0,
    RELEASE = 1,
}

export enum TradeType {
    ALL = 0,
    STOPTRANSACTION = 1,
    TRADING = 2
}
export enum ReleaseType {
    AUTO = 0,
    BYHAND = 1,
}