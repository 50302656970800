import { StockType } from "./type"

let logged = false

export const getStocksConfig = async (chainId: number) => {
    try {
        return (await import(`/${chainId}.ts`)).default.filter(
            (f: StockType) => f.id !== null,
        ) as StockType[]
    } catch (error) {
        if (!logged) {
            console.error('Cannot get stocks config', error, chainId)
            logged = true
        }
        return []
    }
}

export enum RESPONSE_TYPE {
    SUCCESS = 200,
    FAIL = 400
}