export const IconLogo = (props) => {
    return (
        <svg {...props} width="184" height="34" viewBox="0 0 184 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M109.54 26.0609C111.551 26.0609 112.796 27.203 112.796 29.042C112.796 30.881 111.553 31.997 109.54 31.997H107.626V29.4H107V28.7364H107.626V26.0626H109.54V26.0609ZM109.54 31.4014C111.137 31.4014 111.976 30.4898 111.976 29.042C111.976 27.5942 111.138 26.6913 109.54 26.6913H108.428V28.7346H110.079V29.3983H108.428V31.3997H109.54V31.4014Z"
                fill="#23262F"
            />
            <path d="M117.892 26.0609H118.694V31.997H117.892V26.0609Z" fill="#23262F" />
            <path
                d="M127.097 26.6913H124.592V28.6752H126.833V29.3144H124.592V31.3577H127.097V31.9969H123.79V26.0521H127.097V26.6913ZM126.762 25.2015L126.365 25.6783L125.456 25.1247L124.547 25.6783L124.15 25.2015L125.456 24.3929L126.762 25.2015ZM124.804 33.3521C124.804 33.0378 125.05 32.7985 125.377 32.7985C125.687 32.7985 125.933 33.0378 125.933 33.3521C125.933 33.6665 125.687 33.9058 125.377 33.9058C125.05 33.9058 124.804 33.6665 124.804 33.3521Z"
                fill="#23262F"
            />
            <path
                d="M137.028 26.0521V31.997H136.225L132.996 27.2694V31.997H132.193V26.0521H132.996L136.225 30.771V26.0521H137.028Z"
                fill="#23262F"
            />
            <path d="M143.143 26.0609H147.307V26.6913H145.631V31.997H144.828V26.6913H143.143V26.0609Z" fill="#23262F" />
            <path
                d="M151.384 26.0608H152.187V29.8174C152.187 30.9071 152.795 31.3752 153.704 31.3752C154.613 31.3752 155.213 30.9071 155.213 29.8174V26.0608H155.662C156.112 26.0608 156.288 25.7622 156.288 25.3535H157.012C157.012 26.0783 156.615 26.5026 156.015 26.6057V29.8086C156.015 31.342 154.966 32.0563 153.695 32.0563C152.424 32.0563 151.384 31.3403 151.384 29.8086V26.0608ZM152.832 24.7073C152.858 24.1956 153.307 24 153.758 24C154.209 24 154.596 24.2724 154.596 24.7073C154.596 25.1841 154.251 25.3797 153.979 25.4059L153.961 25.5927H153.503L153.486 25.0479C153.944 25.0479 154.059 24.9291 154.059 24.6986C154.059 24.5362 153.917 24.4436 153.75 24.4436C153.564 24.4436 153.396 24.4942 153.361 24.7073H152.832Z"
                fill="#23262F"
            />
            <path
                d="M84.4708 16.885C81.99 16.885 80.0825 15.6459 80 13.3484H82.9694C83.0519 14.2442 83.6052 14.652 84.3882 14.652C85.2204 14.652 85.7268 14.2442 85.7268 13.5757C85.7268 11.4564 79.9665 12.5818 80.0335 8.53941C80.0335 6.37112 81.8115 5.16553 84.2098 5.16553C86.608 5.16553 88.3704 6.42015 88.5021 8.58843H85.4836C85.4345 7.85527 84.9281 7.41404 84.1629 7.39844C83.4936 7.38284 82.9872 7.72379 82.9872 8.44135C82.9872 10.4135 88.6493 9.54889 88.6493 13.364C88.6493 15.2872 87.1479 16.885 84.473 16.885H84.4708Z"
                fill="#23262F"
            />
            <path
                d="M95.7793 16.885C92.5311 16.885 89.8896 14.456 89.8896 11.0175C89.8896 7.57896 92.5333 5.16555 95.7793 5.16555C99.0253 5.16555 101.638 7.57674 101.638 11.0175C101.638 14.4582 99.0275 16.885 95.7793 16.885ZM95.7793 14.3423C97.6064 14.3423 98.7977 13.0231 98.7977 11.0175C98.7977 9.01186 97.6064 7.69261 95.7793 7.69261C93.9522 7.69261 92.7452 8.98066 92.7452 11.0175C92.7452 13.0543 93.9031 14.3423 95.7793 14.3423ZM98.2913 3.53655L97.4257 4.72655L95.7614 3.73266L94.0972 4.72655L93.2316 3.53655L95.7614 1.90755L98.2913 3.53655ZM96.5445 1.28804C96.5289 0.554884 97.1156 0 98.356 0C99.3845 0 100.069 0.472433 100.069 1.25462C100.069 2.03681 99.4001 2.46021 98.8624 2.46021V2.75437H97.6376V1.77608C98.3716 1.77608 98.7643 1.67803 98.7643 1.30365C98.7643 1.09194 98.5523 0.944865 98.2913 0.944865C98.0147 0.944865 97.7693 1.07411 97.7693 1.28804H96.5467H96.5445Z"
                fill="#23262F"
            />
            <path
                d="M111.934 5.19904C114.658 5.19904 116.714 6.72998 117.368 9.20802H114.301C113.828 8.22973 112.962 7.74169 111.902 7.74169C110.189 7.74169 109.016 9.01191 109.016 11.0331C109.016 13.0543 110.191 14.3245 111.902 14.3245C112.962 14.3245 113.828 13.8365 114.301 12.8582H117.368C116.714 15.3363 114.66 16.8516 111.934 16.8516C108.556 16.8516 106.173 14.4716 106.173 11.0331C106.173 7.59462 108.556 5.19904 111.934 5.19904Z"
                fill="#23262F"
            />
            <path
                d="M124.498 16.885C121.252 16.885 118.608 14.456 118.608 11.0175C118.608 7.57896 121.252 5.16555 124.498 5.16555C127.744 5.16555 130.356 7.57674 130.356 11.0175C130.356 14.4582 127.746 16.885 124.498 16.885ZM124.498 14.3423C126.325 14.3423 127.516 13.0231 127.516 11.0175C127.516 9.01186 126.325 7.69261 124.498 7.69261C122.671 7.69261 121.464 8.98066 121.464 11.0175C121.464 13.0543 122.622 14.3423 124.498 14.3423ZM127.01 3.53655L126.144 4.72655L124.48 3.73266L122.816 4.72655L121.95 3.53655L124.48 1.90755L127.01 3.53655ZM125.263 1.28804C125.248 0.554884 125.834 0 127.075 0C128.103 0 128.788 0.472433 128.788 1.25462C128.788 2.03681 128.119 2.46021 127.581 2.46021V2.75437H126.359V1.77608C127.093 1.77608 127.485 1.67803 127.485 1.30365C127.485 1.09194 127.273 0.944865 127.012 0.944865C126.736 0.944865 126.49 1.07411 126.49 1.28804H125.268H125.263Z"
                fill="#23262F"
            />
            <path
                d="M139.737 5.32825C143.392 5.32825 145.741 7.64361 145.741 11.0487C145.741 14.4538 143.392 16.7691 139.737 16.7691H135.445V12.0426H134.531V10.0548H135.445V5.32825H139.737ZM139.575 14.3891C141.681 14.3891 142.903 13.1501 142.903 11.0487C142.903 8.94726 141.681 7.70823 139.575 7.70823H138.254V10.0548H140.456V12.0426H138.254V14.3891H139.575Z"
                fill="#23262F"
            />
            <path
                d="M152.726 16.885C149.48 16.885 146.837 14.456 146.837 11.0175C146.837 7.579 149.48 5.16559 152.726 5.16559C155.972 5.16559 158.585 7.57677 158.585 11.0175C158.585 14.4582 155.975 16.885 152.726 16.885ZM152.726 14.3424C154.553 14.3424 155.745 13.0231 155.745 11.0175C155.745 9.0119 154.553 7.69265 152.726 7.69265C150.899 7.69265 149.692 8.9807 149.692 11.0175C149.692 13.0543 150.85 14.3424 152.726 14.3424ZM155.238 3.53659L154.373 4.72659L152.708 3.7327L151.044 4.72659L150.179 3.53659L152.708 1.90759L155.238 3.53659Z"
                fill="#23262F"
            />
            <path
                d="M170.381 5.32825V16.7691H167.591L162.923 9.71161V16.7691H160.133V5.32825H162.923L167.591 12.4192V5.32825H170.381Z"
                fill="#23262F"
            />
            <path
                d="M183.093 8.94729H180.01C179.571 8.1651 178.819 7.77289 177.759 7.77289C175.966 7.77289 174.774 9.02751 174.774 11.0331C174.774 13.168 175.966 14.3914 177.989 14.3914C179.377 14.3914 180.323 13.6738 180.762 12.3702H177.222V10.3156H183.292V12.9072C182.687 14.8638 180.811 16.8516 177.777 16.8516C174.35 16.8516 171.937 14.4716 171.937 11.0331C171.937 7.59462 174.319 5.19904 177.761 5.19904C180.519 5.19904 182.493 6.58513 183.098 8.94729H183.093Z"
                fill="#23262F"
            />
            <path
                d="M11.5808 28.1466H1.85772C1.65015 28.1466 1.43572 28.3145 1.37911 28.5236L0.0136193 33.6162C-0.0429902 33.8253 0.0822369 33.9932 0.289805 33.9932H10.0129C10.2205 33.9932 10.4349 33.8253 10.4915 33.6162L11.857 28.5236C11.9136 28.3145 11.7884 28.1466 11.5808 28.1466Z"
                fill="#3772FF"
            />
            <path
                d="M20.0654 28.1466H14.9671C14.7595 28.1466 14.5451 28.3145 14.4885 28.5236L13.123 33.6162C13.0664 33.8253 13.1916 33.9932 13.3992 33.9932H18.4975C18.7067 33.9932 18.9195 33.8253 18.9761 33.6162L20.3416 28.5236C20.3982 28.3145 20.2729 28.1466 20.0654 28.1466Z"
                fill="#3772FF"
            />
            <path
                d="M56.2538 14.0047H51.1556C50.948 14.0047 50.7336 14.1726 50.677 14.3817L49.3115 19.4743C49.2549 19.6834 49.3801 19.8513 49.5877 19.8513H54.6859C54.8952 19.8513 55.1079 19.6834 55.1645 19.4743L56.53 14.3817C56.5866 14.1726 56.4614 14.0047 56.2538 14.0047Z"
                fill="#3772FF"
            />
            <path
                d="M56.2814 5.70951L61.3059 5.81403C62.1225 5.81403 62.7366 6.10362 63.15 6.68108C63.5617 7.25854 63.6612 7.94396 63.4485 8.73733L59.0175 25.2575C58.4892 27.0328 57.6091 28.126 55.307 28.1808L48.941 28.1654H46.934L45.3438 33.9983H47.0043L53.7477 33.9932C60.4516 33.9983 64.476 31.8924 66.0886 24.2705L69.9912 9.72261C70.7151 7.0255 70.3823 4.73108 68.9962 2.83933C67.6084 0.947586 65.5653 0 62.8652 0H58.1461H54.5917L53.0426 5.69237L56.2814 5.70779V5.70951Z"
                fill="#3772FF"
            />
            <path
                d="M24.8787 21.0063L23.6847 25.3278L22.2643 30.1103C21.4598 32.8434 22.0979 34 25.7604 34H31.104H42.136H42.8393L44.4261 28.1672L33.2929 28.1946C30.953 28.1946 29.8723 27.4578 30.5259 25.321L31.6804 21.0149L24.8752 21.008L24.8787 21.0063Z"
                fill="#3772FF"
            />
            <path
                d="M45.4863 24.274C45.4863 24.274 45.4863 24.2723 45.4863 24.2706L45.6133 23.7959C46.3372 21.0988 46.0044 18.8044 44.6183 16.9127C43.2305 15.0209 41.1995 14.0751 38.5234 14.0751H14.9532C14.159 14.0751 13.5535 13.7923 13.14 13.2251C12.7249 12.6597 12.6271 11.9674 12.845 11.1518L13.4917 8.73738C13.7113 7.92174 14.1796 7.23118 14.8984 6.664C15.6171 6.09854 16.3736 5.81409 17.1679 5.81409L26.258 5.80895C26.3266 5.80895 26.3901 5.80209 26.4621 5.74555C26.5342 5.689 26.5445 5.61703 26.5633 5.54849L27.9906 0.305066C28.0146 0.219389 28.0146 0.126858 27.9477 0.0668844C27.9117 0.0343272 27.8585 0.00177002 27.8053 0.00177002H18.7289C16.0511 0.00177002 13.5123 0.947642 11.1107 2.83939C8.70905 4.73113 7.14972 7.01528 6.43095 9.6884L6.29372 10.199C5.57666 12.8739 5.91117 15.1563 7.29725 17.048C8.68332 18.9398 10.7161 19.8857 13.3922 19.8857H36.964C37.7583 19.8857 38.3604 20.1752 38.7738 20.7527C39.1855 21.3302 39.285 22.0156 39.0723 22.8089L38.4153 25.2559L38.2369 25.8145L37.8458 27.0345H44.735"
                fill="#3772FF"
            />
            <path
                d="M33.8968 12.9475L34.9415 9.6335C36.0875 6.22356 36.7771 5.74205 40.1359 5.65124L50.5383 5.70436C50.5658 5.70436 52.1045 0.00341797 52.1045 0.00341797L33.3136 0.0188398C30.5809 0.00341797 30.4968 0.661416 29.7095 3.18203L27.0522 12.9492H33.8968V12.9475Z"
                fill="#3772FF"
            />
        </svg>
    )
}
