import { useWeb3React } from '@pancakeswap/wagmi'
import { useRouter, NextRouter } from 'next/router'
import { useEffect } from 'react'
import { usePreviousValue } from '@pancakeswap/hooks'
import { EXCHANGE_PAGE_PATHS } from 'config/constants/exchange'
import { isChainSupported } from 'utils/wagmi'
import { useProvider } from 'wagmi'
import { ChainId } from '@pancakeswap/sdk'
import { useActiveChainId } from './useActiveChainId'
import { useSwitchNetworkLoading } from './useSwitchNetworkLoading'

const getHashFromRouter = (router: NextRouter) => {
    return router.asPath.match(/#([a-z0-9]+)/gi)
}

export function useNetworkConnectorUpdater() {
    const { chainId } = useActiveWeb3React()
    const previousChainId = usePreviousValue(chainId)
    const [loading] = useSwitchNetworkLoading()
    const router = useRouter()

    useEffect(() => {
        if (loading || !router.isReady) return
        const parsedQueryChainId = Number(router.query.chainId)
        if (!parsedQueryChainId && chainId === ChainId.ONUS) return
        if (parsedQueryChainId !== chainId && isChainSupported(chainId)) {
            const removeQueriesFromPath =
                previousChainId !== chainId &&
                EXCHANGE_PAGE_PATHS.some((item) => {
                    return router.pathname.startsWith(item)
                })
            const uriHash = getHashFromRouter(router)?.[0]
            router.replace(
                {
                    query: {
                        ...(!removeQueriesFromPath && router.query),
                        chainId,
                    },
                    ...(uriHash && { hash: uriHash }),
                },
                undefined,
            )
        }
    }, [previousChainId, chainId, loading, router])
}

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = () => {
    const web3React = useWeb3React()
    const { chainId, isWrongNetwork } = useActiveChainId()
    const provider = useProvider({ chainId })

    return {
        provider,
        ...web3React,
        chainId,
        isWrongNetwork,
    }
}

export default useActiveWeb3React
