import { createReducer } from '@reduxjs/toolkit'
import { HomeTab, ReleaseType, TradeType } from '.'
import { fetchActiveTab, fetchReleaseType, fetchTradingType, fetchLoading, fetchDataStocks, fetchNumberPageCurrent, fetchOwnerMarketplace } from './actions'
import { StockItem } from './type'

interface global {
    activeTab:number,
    tradingType: number,
    releaseType:number,
    isLoading: boolean,
    dataStocks: StockItem[],
    pageActiveManage:number,
    isOwnerMarketplace:boolean
}
export const initialState: global = {
    activeTab:HomeTab.MANAGE,
    tradingType: TradeType.ALL,
    releaseType: ReleaseType.AUTO,
    isLoading: false,
    dataStocks: [],
    pageActiveManage:0,
    isOwnerMarketplace:false
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchActiveTab, (state, action) => {
            state.activeTab = action.payload.activeTab
        })
        .addCase(fetchTradingType, (state, action) => {
            state.tradingType = action.payload.tradingType
        })
        .addCase(fetchReleaseType, (state, action) => {
            state.releaseType = action.payload.releaseType
        })
        .addCase(fetchLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(fetchDataStocks, (state, action) => {
            state.dataStocks = action.payload.dataStocks
        })
        .addCase(fetchNumberPageCurrent, (state, action) => {
            state.pageActiveManage = action.payload.pageActiveManage
        })
        .addCase(fetchOwnerMarketplace, (state, action) => {
            state.isOwnerMarketplace = action.payload.isOwnerMarketplace
        })
)