import { parseUnits } from '@ethersproject/units'
import {
    ButtonMenu,
    Flex,
    ButtonMenuItem,
    CloseIcon,
    Heading,
    IconButton,
    InjectedModalProps,
    ModalBody,
    ModalContainer,
    ModalHeader as UIKitModalHeader,
    Modal,
} from '@pancakeswap/uikit'
import { useWeb3React } from '@pancakeswap/wagmi'
import { useState } from 'react'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import { useBalance } from 'wagmi'
import WalletInfo from './WalletInfo'
import WalletTransactions from './WalletTransactions'
import WalletWrongNetwork from './WalletWrongNetwork'

export enum WalletView {
    WALLET_INFO,
    TRANSACTIONS,
    WRONG_NETWORK,
}

interface WalletModalProps extends InjectedModalProps {
    initialView?: WalletView
}

export const LOW_BNB_BALANCE = parseUnits('2', 'gwei')

const ModalHeader = styled(UIKitModalHeader)`
    background: ${({ theme }) => theme.colors.gradientBubblegum};
`

const Tabs = styled.div`
    background-color: ${({ theme }) => theme.colors.dropdown};
    border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
    padding: 0px;
    margin-bottom: 1rem;
`
const WrapIconButton = styled(IconButton)`
    position: absolute;
    top: 15px;
    right: 15px;
    background: black !important;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    svg {
        fill: #fff;
        width: 25px;
    }
`

const ModalTitle = styled(Flex)`
    align-items: center;
    height: 40px;
    margin-top: 5px;
    flex: 1;
    justify-content: center;
    color: #23262f;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    gap: 10px;
`

export const CsModal = styled(Modal)`
    @media screen and (max-width: 600px) {
        width: 100%;
    }
`

const WalletModal: React.FC<React.PropsWithChildren<WalletModalProps>> = ({
    initialView = WalletView.WALLET_INFO,
    onDismiss,
}) => {
    const [view, setView] = useState(initialView)
    const { t } = useTranslation()
    const { account } = useWeb3React()
    const { data, isFetched } = useBalance({ address: account })
    const hasLowNativeBalance = isFetched && data && data.value.lte(LOW_BNB_BALANCE)

    const handleClick = (newIndex: number) => {
        setView(newIndex)
    }

    const TabsComponent: React.FC<React.PropsWithChildren> = () => (
        <Tabs>
            <ButtonMenu scale="sm" variant="subtle" onItemClick={handleClick} activeIndex={view} fullWidth>
                <ButtonMenuItem>{t('Wallet')}</ButtonMenuItem>
                <ButtonMenuItem>{t('Transactions')}</ButtonMenuItem>
            </ButtonMenu>
        </Tabs>
    )

    return (
        <CsModal title="Your Wallet" onDismiss={onDismiss}>
            <Flex width="100%" height="auto" flexDirection="column">
                <ModalBody p="0px" width="100%">
                    {view !== WalletView.WRONG_NETWORK && <TabsComponent />}
                    {view === WalletView.WALLET_INFO && (
                        <WalletInfo
                            hasLowNativeBalance={hasLowNativeBalance}
                            switchView={handleClick}
                            onDismiss={onDismiss}
                        />
                    )}
                    {view === WalletView.TRANSACTIONS && <WalletTransactions onDismiss={onDismiss} />}
                    {view === WalletView.WRONG_NETWORK && <WalletWrongNetwork onDismiss={onDismiss} />}
                </ModalBody>
            </Flex>
        </CsModal>
    )
}

export default WalletModal
