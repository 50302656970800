import { StockType } from "config/constants/stocks/type";
import multicall from "utils/multicall";
import stockAbi from "config/abi/stocks.json"
import marketplaceAbi from "config/abi/marketplace.json"
import BigNumber from "bignumber.js";
import { DEFAULT_ID } from "config";
import { StockItem } from "./type";
import { useEffect, useState } from "react";

export const getDataStocks = async (config:StockType[], chainId:number, account:string, contractMarketplace:string): Promise<{dataStocks:StockItem[]}> => {
    try {
        const fetchIssuePrice = async () => {
            const calls = config?.map((dt) => {
                return {
                    address: dt?.contract?.address,
                    name: 'issuePrice',
                    params: []
                }
            })
            const result = await multicall(stockAbi, calls, chainId);
            const mergeData = result?.map((rs, index) => {
                return {
                    ...config[index],
                    issuePrice: new BigNumber(rs).toString()
                }
            })
            return mergeData
        }
        const fetchApprovedRole = async (issuePrice) => {
            const calls = issuePrice?.map((dt) => {
                return {
                    address: dt?.contract?.address,
                    name: 'approver',
                    params: []
                }
            })
            const result = await multicall(stockAbi, calls, chainId);
            const mergeData = issuePrice?.map((rs, index) => {
                return {
                    ...rs,
                    approveWallet: result[index][0]
                }
            })
            return mergeData
        }
        const fetchTotalRelease = async (approver) => {
            const calls = approver?.map((dt) => {
                return {
                    address: dt?.contract?.address,
                    name: 'totalSupply',
                    params: [DEFAULT_ID]
                }
            })
            const result = await multicall(stockAbi, calls, chainId);
           
            const mergeData = approver?.map((rs, index) => {
                console.log("whitelist",new BigNumber(result[index]?.toString()).toString());
                return {
                    ...rs,
                    totalReleaseStocks: new BigNumber(result[index]?.toString()).toString(),
                    totalValueOfStocks: new BigNumber(rs?.issuePrice).multipliedBy(result[index]?.toString()).toString()
                }
            })
            return mergeData
        }
        const fetchPause = async (release) => {
            const calls = release?.map((dt) => {
                return {
                    address: dt?.contract?.address,
                    name: 'paused',
                    params: []
                }
            })
            const result = await multicall(stockAbi, calls, chainId);
            const mergeData = release?.map((rs, index) => {
                return {
                    ...rs,
                    isPause: result[index][0]
                }
            })
            return mergeData
        }
        const fetchAdminRole = async (pause) => {
            const calls = pause?.map((dt) => {
                return {
                    address: dt?.contract?.address,
                    name: 'ADMIN_ROLE',
                    params: []
                }
            })
            const result = await multicall(stockAbi, calls, chainId);
            const mergeData = pause?.map((rs, index) => {
                return {
                    ...rs,
                    adminRole: result[index][0]
                }
            })
            return mergeData
        }
        const fetchAdmin = async (adminRole) => {
            console.log("akjdfkasjla",account);
            
            if(account?.length > 0 ) {
                const calls = adminRole?.map((dt) => {
                    return {
                        address: dt?.contract?.address,
                        name: 'hasRole',
                        params: [dt?.adminRole, account]
                    }
                })
                const result = await multicall(stockAbi, calls, chainId);
                const mergeData = adminRole?.map((rs, index) => {
                    return {
                        ...rs,
                        isAdmin: result[index][0]
                    }
                })
                return mergeData
            } else {
                const mergeData = adminRole?.map((rs) => {
                    return {
                        ...rs,
                        isAdmin: false
                    }
                })
                return mergeData
            }
        }
        const fetchIsStocksOnWhitelist = async (adminRole) => {
            const calls = adminRole?.map((dt) => {
                return {
                    address: contractMarketplace,
                    name: 'tradableStock',
                    params: [dt?.contract?.address]
                }
            })
            const result = await multicall(marketplaceAbi, calls, chainId);
            const mergeData = adminRole?.map((rs, index) => {
                return {
                    ...rs,
                    isWhitelist: result[index][0]
                }
            })
            return mergeData
        }
        const fetchCompanyName = async (approver) => {
            const calls = approver?.map((dt) => {
                return {
                    address: dt?.contract?.address,
                    name: 'companyName',
                    params: []
                }
            })
            const result = await multicall(stockAbi, calls, chainId);
            const mergeData = approver?.map((rs, index) => {
                return {
                    ...rs,
                    companyName:result[index][0]
                }
            })
            return mergeData
        }
        const fetchStockSymbol = async (approver) => {
            const calls = approver?.map((dt) => {
                return {
                    address: dt?.contract?.address,
                    name: 'stockSymbol',
                    params: []
                }
            })
            const result = await multicall(stockAbi, calls, chainId);
            console.log("result",result);
            
            const mergeData = approver?.map((rs, index) => {
                return {
                    ...rs,
                    symbol: result[index][0]
                }
            })
            
            return mergeData
        }
        const issuePrice = await fetchIssuePrice()
        const approver = await fetchApprovedRole(issuePrice)
        const release = await fetchTotalRelease(approver)
        const pause = await fetchPause(release)
        const companyName = await fetchCompanyName(pause) 
        const stockSymbol = await fetchStockSymbol(companyName)
        const adminRole = await fetchAdminRole(stockSymbol)
        const admin = await fetchAdmin(adminRole)
        const whitelist = await fetchIsStocksOnWhitelist(admin)
        console.log("whitelist",whitelist);
        

        return { dataStocks: whitelist }
        
    } catch (error) {
        console.log('error', error)
        return { dataStocks: [] }
    }
} 
export const getDataOwnerMarketplace = async (chainId:number, account:string, contractMarketplace:string): Promise<{isOwnerMarketplace:boolean}> => {
    try {
        
        const fetchAdminRole = async () => {
            const calls = [
                {
                    address: contractMarketplace,
                    name: 'MARKET_MANAGER_ROLE',
                    params: []
                }
            ]
            const [result] = await multicall(marketplaceAbi, calls, chainId);
            return result[0]
        }
        const fetchOwner = async (role:string) => {
            if(account?.length > 0) {
                const calls = [
                    {
                        address: contractMarketplace,
                        name: 'hasRole',
                        params: [role, account]
                    }
                ]
                const [result] = await multicall(marketplaceAbi, calls, chainId);
                return result[0]
            } else {
                return false
            }
        }
        const managerRole = await fetchAdminRole()
        const owner = await fetchOwner(managerRole)
        return { isOwnerMarketplace: owner }
    } catch (error) {
        console.log('error', error)
        return { isOwnerMarketplace: false }
    }
} 

export const getDataStocksOnWhitelist = (stocksAddress: string, contractMarketplace:string, chainId:number, currenTime:number) => {
    const [ isWhitelist, setIsWhitelist ] = useState(false)
    useEffect(() => {
        try {
            const fetchIsStocksOnWhitelist = async () => {
                const calls = [
                    {
                        address: contractMarketplace,
                        name: 'tradableStock',
                        params: [stocksAddress]
                    }
                ]
                const result = await multicall(marketplaceAbi, calls, chainId);
                setIsWhitelist(result[0][0])
            }
            if(stocksAddress?.length > 0 ) {
                fetchIsStocksOnWhitelist()
            } else {
                setIsWhitelist(false)
            }
        } catch (error) {
            console.log(error)
            setIsWhitelist(false)
        }
    },[stocksAddress, contractMarketplace, chainId, currenTime])
    return { isWhitelist }
}