import { ChainId, Token, WBNB } from '@pancakeswap/sdk'
import { BUSD_TESTNET, CAKE_TESTNET } from './common'

export const bscTestnetTokens = {
    wbnb: WBNB[ChainId.BSC_TESTNET],
    cake: CAKE_TESTNET,
    busd: BUSD_TESTNET,
    syrup: new Token(
        ChainId.BSC_TESTNET,
        '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
        18,
        'SYRUP',
        'SyrupBar Token',
        'https://pancakeswap.finance/',
    ),
    bake: new Token(
        ChainId.BSC_TESTNET,
        '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
        18,
        'BAKE',
        'Bakeryswap Token',
        'https://www.bakeryswap.org/',
    ),
    hbtc: new Token(ChainId.BSC_TESTNET, '0x3Fb6a6C06c7486BD194BB99a078B89B9ECaF4c82', 18, 'HBTC', 'Huobi BTC'),
    wbtc: new Token(ChainId.BSC_TESTNET, '0xfC8bFbe9644e1BC836b8821660593e7de711e564', 8, 'WBTC', 'Wrapped BTC'),
    usdc: new Token(
        ChainId.BSC_TESTNET,
        '0x2fB98DCc527c1403F92c6Bed05a50725d3005636',
        18,
        'USDC',
        'Binance-Peg USD Coin',
    ),
    runtogether: new Token(
        ChainId.BSC_TESTNET,
        '0x242a227B38f704983FF101DC7De573ED8111601e',
        18,
        'RUN ',
        'Run Together',
        'https://runtogether.net/',
    ),
    runtogetherBoxNft: new Token(
        ChainId.BSC_TESTNET,
        '0x00fbf2a59c65d495055dee6e3925ff8d7c0bdad6',
        0,
        'RTBN',
        'Run Together Box NFT',
        'https://runtogether.net/',
    ),
    pib: new Token(
        ChainId.BSC_TESTNET,
        '0x30f1917E5ea3046b336BD2f7AA2F9A1b90F5Ae5F',
        18,
        'PiB',
        'PiBridge',
        'https://pibridge.org/#',
    ),
    wpi: new Token(
        ChainId.BSC_TESTNET,
        '0x68b899f59321aC80063f4f7DC52b0d5C35752E4C',
        18,
        'WPi',
        'Wrapped Pi',
        'https://pibridge.org/#',
    ),

    Demo1 : new Token(
        ChainId.BSC_TESTNET,
        '0x1a199552ab456Eed05cbC1DA993AC5f46C423ea7',
        0,
        'CPY',
        'Company Y',
        '',
    ),
    Demo2 : new Token(
        ChainId.BSC_TESTNET,
        '0xBa38a28d81E6f13426e69E13515E7BCa35E989cB',
        0,
        'TD0610',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo3 : new Token(
        ChainId.BSC_TESTNET,
        '0x9677cEf05cfa5fF9aD343769b0a4a638732d692f',
        0,
        'TC0610',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo4 : new Token(
        ChainId.BSC_TESTNET,
        '0x77d7f4046790c21e45eBfA6e786E9659254578b2',
        0,
        'TD0710',
        'Công ty cổ phần deltalabs',
        '',
    ),
    Demo5 : new Token(
        ChainId.BSC_TESTNET,
        '0x056851E2A038bB3f63d0Badd4AF54CF402221e90',
        0,
        'TC0710',
        'Công ty cổ phần deltalabs',
        '',
    ),
    Demo6 : new Token(
        ChainId.BSC_TESTNET,
        '0xEE26E29875A251641697cAa81eE86b57Bad55477',
        0,     
        'ADC',
        'Công ty A',
        '',
    ),
    Demo7 : new Token(
        ChainId.BSC_TESTNET,
        '0x34135409b3D8f138ab240DA7F8B57584B7f7D0A5',
        0,     
        'TD0910',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo8 : new Token(
        ChainId.BSC_TESTNET,
        '0x71b77A453e00439249d14e2c6d5987f8c44c2ea7',
        0,     
        'TC0910',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo9 : new Token(
        ChainId.BSC_TESTNET,
        '0xEB98c28061f8F39e0D5F94f99351019368E78f77',
        0,     
        '88',
        'Thu Cong kaito 88',
        '',
    ),
    Demo10 : new Token(
        ChainId.BSC_TESTNET,
        '0xa197002dc9cbcbc158db7d96f8bd71af880b8e5b',
        0,     
        'DEMO991',
        'KAITO1223',
        '',
    ),
    Demo11 : new Token(
        ChainId.BSC_TESTNET,
        '0xDb995339AcF89935138cDc43aa7cf18a2DedF581',
        0,     
        'TC09102003',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo12 : new Token(
        ChainId.BSC_TESTNET,
        '0x4758C49C51C3C0894E7c3dD1f2aCf3177267ff3A',
        0,     
        'TD09102003',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo13 : new Token(
        ChainId.BSC_TESTNET,
        '0x883E1089a841C279f1ABC3ffA23Af962bE536693',
        0,     
        'TC1010',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo14 : new Token(
        ChainId.BSC_TESTNET,
        '0x3E0a11D784d556d6072729F69CBa6b897d819731',
        0,     
        'TD1010',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo15 : new Token(
        ChainId.BSC_TESTNET,
        '0x42D88448F78ed80A7A490d43E06134305eEFD4aa',
        0,     
        'TEST10/10',
        'Demo test',
        '',
    ),
    Demo16 : new Token(
        ChainId.BSC_TESTNET,
        '0x7e52B5d672155A8F5b1f156139EC9E368E3EC927',
        0,     
        'TC10102023',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo17 : new Token(
        ChainId.BSC_TESTNET,
        '0xa08AF6A0Eb3bfec040b5CDf32D6d470a42CA923F',
        0,     
        'TD10102023',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo18 : new Token(
        ChainId.BSC_TESTNET,
        '0x4214D720Ab92Cb1E7faDcB9D49db43573f68D7C8',
        0,     
        'TD1011',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo19 : new Token(
        ChainId.BSC_TESTNET,
        '0xe2e9FBea7140A4f8985bb5CD126F7a8588798074',
        0,     
        'TC1011',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo20 : new Token(
        ChainId.BSC_TESTNET,
        '0x7892AD7f1294Cec6DBEF826947A04d15691e93AE',
        0,     
        'TD1210',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo21 : new Token(
        ChainId.BSC_TESTNET,
        '0x368B981f191f38a5660Be4f2b493CDC54882F6a5',
        0,     
        'TC1210',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo22 : new Token(
        ChainId.BSC_TESTNET,
        '0x9b9a01Eadb2282b68cffE435c007DB8EFbb42905',
        0,     
        'TD1610',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo23 : new Token(
        ChainId.BSC_TESTNET,
        '0xa343488AF0921fe3b48F400f78BcAf27edD6A894',
        0,     
        'TC1610',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo24 : new Token(
        ChainId.BSC_TESTNET,
        '0xF18698eEFE4ea16039A0F35e51384193E331FA2b',
        0,     
        'DEMO_1610',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo25 : new Token(
        ChainId.BSC_TESTNET,
        '0xa1ec1bEac8af6BfC4466cB89657aEC7F8C4Ecce2',
        0,     
        'TEST_EMAIL',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo26 : new Token(
        ChainId.BSC_TESTNET,
        '0x4520A4BCbDF68D8491Fefbf84Fe904F5223FE0BF',
        0,     
        'TC1710',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo27 : new Token(
        ChainId.BSC_TESTNET,
        '0xd117261A7964B9323f69193c95665d664FAD6D2F',
        0,     
        'DEMO_1710',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo28 : new Token(
        ChainId.BSC_TESTNET,
        '0xE31b0974fBFA5c4CCF5d50a2aaA528d40CD211bE',
        0,     
        'EMAIL',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    

} 
