import { createAction } from '@reduxjs/toolkit'
import { StockItem } from './type'

export const fetchActiveTab = createAction<{activeTab:number}>('home/fetchActiveTab')
export const fetchTradingType = createAction<{tradingType:number}>('home/fetchTradingType')
export const fetchReleaseType = createAction<{releaseType:number}>('home/fetchReleaseType')

// for fetch data
export const fetchLoading = createAction<{isLoading:boolean}>('home/fetchLoading')
export const fetchDataStocks = createAction<{dataStocks:StockItem[]}>('home/fetchDataStoks')
export const fetchNumberPageCurrent = createAction<{pageActiveManage:number}>('home/fetchNumberPageCurrent')
export const fetchOwnerMarketplace = createAction<{isOwnerMarketplace:boolean}>('home/fetchOwnerMarketplace')

