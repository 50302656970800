// loginContext.tsx

import useActiveWeb3React from 'hooks/useActiveWeb3React'
import React, { createContext, useEffect, useState } from 'react'
import { WHITE_LIST } from 'config'

interface AuthContextProps {
    isLoggedIn: boolean
}

export const AuthContext = createContext<AuthContextProps>({
    isLoggedIn: false
})

export const AuthProvider = ({ children }: any) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const { account } = useActiveWeb3React()
    useEffect(() => {
        if (account?.length > 0 ){
            const findItem = WHITE_LIST.find((dt) => dt?.toLowerCase() === account?.toLowerCase())
            if( findItem ) {
                setIsLoggedIn(!false)
            } else {
                setIsLoggedIn(false)
            }
        } else {
            setIsLoggedIn(false)
        }
    },[account])
    return <AuthContext.Provider value={{ isLoggedIn }}>{children}</AuthContext.Provider>
}
