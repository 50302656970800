import { createReducer } from '@reduxjs/toolkit'
import { fetchFile, fetchDataStock, fetchLoading, fetchNumberPageCurrent } from './actions'
import { Stock, ListShareHolders, File } from './type'

interface global {
    file: File,
    stock:Stock,
    isLoading: boolean,
    pageActiveManage:number
}
export const initialState: global = {
    file: null,
    stock: null,
    isLoading: false,
    pageActiveManage: 0
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchFile, (state, action) => {
            state.file = action.payload.file
        })
        .addCase(fetchDataStock, (state, action) => {
            state.stock = action.payload.stock
        })
        .addCase(fetchLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(fetchNumberPageCurrent, (state, action) => {
            state.pageActiveManage = action.payload.pageActiveManage
        })
)