import styled, { DefaultTheme } from "styled-components";
import { space, typography, layout } from "styled-system";
import getThemeValue from "../../util/getThemeValue";
import { TextProps } from "./types";

interface ThemedProps extends TextProps {
    theme: DefaultTheme;
}

const getColor = ({ color, theme }: ThemedProps) => {
    return getThemeValue(theme, `colors.${color}`, color);
};

const Text = styled.div<TextProps>`
    color: ${getColor};
    font-weight: ${({ bold }) => (bold ? 600 : 400)};
    font-style: normal;
    line-height: 1.5;
    ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
    ${({ ellipsis }) =>
        ellipsis &&
        `white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;`}

    ${space}
    ${typography}
    ${layout}

    ${({ small }) => small && `font-size: 12px;`}
`;

Text.defaultProps = {
    color: "text",
    small: false,
    fontSize: "14px",
    ellipsis: false,
};

export default Text;
