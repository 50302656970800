import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 19 19" {...props}>
            <path d="M9.02467 18.0104H6.01634C2.54092 18.0104 0.989258 16.4587 0.989258 12.9833V9.97499C0.989258 6.49957 2.54092 4.94791 6.01634 4.94791H8.39134C8.71592 4.94791 8.98509 5.21707 8.98509 5.54166C8.98509 5.86624 8.71592 6.13541 8.39134 6.13541H6.01634C3.18217 6.13541 2.17676 7.14082 2.17676 9.97499V12.9833C2.17676 15.8175 3.18217 16.8229 6.01634 16.8229H9.02467C11.8588 16.8229 12.8643 15.8175 12.8643 12.9833V10.6083C12.8643 10.2837 13.1334 10.0146 13.458 10.0146C13.7826 10.0146 14.0518 10.2837 14.0518 10.6083V12.9833C14.0518 16.4587 12.5001 18.0104 9.02467 18.0104Z" fill="#5D5D5B"/>
            <path d="M13.4583 11.2021H10.9249C8.70035 11.2021 7.79785 10.2996 7.79785 8.07501V5.54168C7.79785 5.30418 7.94035 5.08251 8.16202 4.99543C8.38368 4.90043 8.63702 4.95584 8.81118 5.12209L13.8779 10.1888C14.0441 10.355 14.0995 10.6163 14.0045 10.8379C13.9174 11.0596 13.6958 11.2021 13.4583 11.2021ZM8.98535 6.97459V8.07501C8.98535 9.65043 9.34952 10.0146 10.9249 10.0146H12.0254L8.98535 6.97459Z" fill="#5D5D5B"/>
            <path d="M12.3503 2.17709H9.18359C8.85901 2.17709 8.58984 1.90793 8.58984 1.58334C8.58984 1.25876 8.85901 0.989594 9.18359 0.989594H12.3503C12.6748 0.989594 12.944 1.25876 12.944 1.58334C12.944 1.90793 12.6748 2.17709 12.3503 2.17709Z" fill="#5D5D5B"/>
            <path d="M5.54199 4.55209C5.21741 4.55209 4.94824 4.28293 4.94824 3.95834C4.94824 2.31959 6.27824 0.989594 7.91699 0.989594H9.99116C10.3157 0.989594 10.5849 1.25876 10.5849 1.58334C10.5849 1.90793 10.3157 2.17709 9.99116 2.17709H7.91699C6.93533 2.17709 6.13574 2.97668 6.13574 3.95834C6.13574 4.28293 5.86658 4.55209 5.54199 4.55209Z" fill="#5D5D5B"/>
            <path d="M15.1924 14.0521C14.8678 14.0521 14.5986 13.7829 14.5986 13.4583C14.5986 13.1338 14.8678 12.8646 15.1924 12.8646C16.0949 12.8646 16.8232 12.1283 16.8232 11.2338V6.33334C16.8232 6.00876 17.0924 5.73959 17.417 5.73959C17.7415 5.73959 18.0107 6.00876 18.0107 6.33334V11.2338C18.0107 12.7854 16.744 14.0521 15.1924 14.0521Z" fill="#5D5D5B"/>
            <path d="M17.417 6.92708H15.042C12.9362 6.92708 12.0732 6.06417 12.0732 3.95833V1.58333C12.0732 1.34583 12.2157 1.12417 12.4374 1.03708C12.6591 0.942085 12.9124 0.997501 13.0866 1.16375L17.8366 5.91375C18.0028 6.08 18.0582 6.34125 17.9632 6.56292C17.8762 6.78458 17.6545 6.92708 17.417 6.92708ZM13.2607 3.01625V3.95833C13.2607 5.40708 13.5932 5.73958 15.042 5.73958H15.9841L13.2607 3.01625Z" fill="#5D5D5B"/>
        </Svg>
    );
};

export default Icon;
