import React, { cloneElement, Children, ReactElement } from "react";
import styled, { DefaultTheme } from "styled-components";
import { space } from "styled-system";
import { scales, variants } from "../Button/types";
import { ButtonMenuProps } from "./types";

interface StyledButtonMenuProps extends ButtonMenuProps {
    theme: DefaultTheme;
}

const getBackgroundColor = ({ theme, variant }: StyledButtonMenuProps) => {
    return theme.colors[variant === variants.SUBTLE ? "input" : "tertiary"];
};

const getBorderColor = ({ theme, variant }: StyledButtonMenuProps) => {
    return theme.colors[variant === variants.SUBTLE ? "inputSecondary" : "disabled"];
};

const StyledButtonMenu = styled.div<StyledButtonMenuProps>`
    background: ${({ theme }) => theme.colors.backgroundAlt2};
    border-radius: 0px;
    display: ${({ fullWidth }) => (fullWidth ? "flex" : "inline-flex")};
    border-radius: 12px;
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};

    & > button,
    & > a {
        flex: ${({ fullWidth }) => (fullWidth ? 1 : "auto")};
    }

    & > button + button,
    & > a + a {
        margin-left: 2px; // To avoid focus shadow overlap
    }

    & > button,
    & a {
        box-shadow: none;
    }

    ${({ disabled, theme, variant }) => {
        if (disabled) {
            return `
        opacity: 0.5;

        & > button:disabled {
          background-color: transparent;
          color: ${variant === variants.PRIMARY ? theme.colors.text : theme.colors.text};
          border: 1px solid ${variant === variants.PRIMARY ? theme.colors.text : theme.colors.text};
        }
    `;
        }
        return "";
    }}
    ${space}
`;

const ButtonMenu: React.FC<React.PropsWithChildren<ButtonMenuProps>> = ({
    activeIndex = 0,
    scale = scales.MD,
    variant = variants.PRIMARY,
    onItemClick,
    disabled,
    children,
    fullWidth = false,
    ...props
}) => {
    return (
        <StyledButtonMenu disabled={disabled} variant={variant} fullWidth={fullWidth} {...props}>
            {Children.map(children, (child: ReactElement, index) => {
                return cloneElement(child, {
                    isActive: activeIndex === index,
                    onClick: onItemClick ? () => onItemClick(index) : undefined,
                    scale,
                    variant,
                    disabled,
                });
            })}
        </StyledButtonMenu>
    );
};

export default ButtonMenu;
