import { getFullDecimalMultiplier } from 'utils/getFullDecimalMultiplier'
import { ChainId } from '@pancakeswap/sdk'
import BigNumber from 'bignumber.js'

export const BSC_BLOCK_TIME = 3
export const BASE_BSC_SCAN_URLS = {
    [ChainId.BSC]: 'https://bscscan.com',
    [ChainId.BSC_TESTNET]: 'https://testnet.bscscan.com',
}
// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 0.0001735
export const BLOCKS_PER_DAY = (60 / BSC_BLOCK_TIME) * 60 * 24
export const BLOCKS_PER_YEAR = BLOCKS_PER_DAY * 365 // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR

export const CAKE_PER_BLOCKV2 = new BigNumber(0.0001735)
export const BLOCKS_PER_YEARV2 = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const CAKE_PER_YEARV2 = CAKE_PER_BLOCKV2.times(BLOCKS_PER_YEARV2)

export const BASE_URL = 'https://board.runtogether.net'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const DEFAULT_TOKEN_DECIMAL = getFullDecimalMultiplier(18)
export const DEFAULT_GAS_LIMIT = 250000
export const BOOSTED_FARM_GAS_LIMIT = 500000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const startTimeStake = 1662469200000
export const LIMIT_VOTING = 100
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
export const AMOUNT_FULL_POOL = '2500000'

export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.BSC]
export const BASE_BSC_URL = BASE_BSC_SCAN_URLS[ChainId.BSC]
export const URL = process.env.NEXT_PUBLIC_API
export const TRANDING_FEE = 3
export const TRANSFER_FEE = 5

export const DEFAULT_ID = 0
export const ITEMSPERPAGE = 8

export const SUPPORTED_PAGE = [ChainId.ONUS]

export const WEB3_API_KEY = process.env.NEXT_PUBLIC_WEB3STORADE

export const MAX_AMOUNT_TRANSFER_NFT = 50

export const WHITE_LIST = [
    "0x1083c2ea0607d0a81D23D889B480E633b3Ab62de",
    "0x27fC7C440458C48A8884b387d82b227b90D5d173",
    '0x15570b4a6f5FD034aF57B7Bb95698504238e8Ff7',
    '0x42F10Bb701ed230222aC6F748320040A0e3ddfAD'
]

export const NFT_TYPE = {
    DIAMOND: 4,
    GOLD: 3,
    SILVER: 2,
    BRONZE: 1,
}

export const ModalTypeEnum = {
    MODAL_CREATE_SELL_ORDER: 'MODAL_CREATE_SELL_ORDER',
    MODAL_CREATE_BUY_ORDER: 'MODAL_CREATE_BUY_ORDER',
    MODAL_CANCEL_ORDER_BOOK: 'MODAL_CANCEL_ORDER_BOOK',
    MODAL_BUY_SHARES: 'MODAL_BUY_SHARES',
    MODAL_SELL_SHARES: 'MODAL_SELL_SHARES',
    MODAL_TRANSFER: 'MODAL_TRANSFER',
    MODAL_IMPORT:'MODAL_IMPORT',
    NULL: 'NULL',
}

export const PAYMENT_TOKEN = {
    VND: 'VND', // VNDC
    USD: 'USD', // USDT
}

export const PAYMENT = PAYMENT_TOKEN.VND

export const formatNumberPayment = (price, minimum = 0, maximum = 0) => {
    if (!price) return 0
    if (PAYMENT === PAYMENT_TOKEN.USD) {
        return Number(new BigNumber(price).decimalPlaces(5, 1).toString())
            .toLocaleString('en-US', { minimumFractionDigits: 5, maximumFractionDigits: 5 })
            .replace(/\.?0+$/, '')
    }
    return Number(new BigNumber(price).decimalPlaces(0, 1).toString()).toLocaleString('en-US', {
        minimumFractionDigits: minimum,
        maximumFractionDigits: maximum,
    })
}
