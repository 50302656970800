import { Button, Modal, Text, Grid, Message, MessageText, Heading } from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import styled from 'styled-components'
import { useSwitchNetwork, useSwitchNetworkLocal } from 'hooks/useSwitchNetwork'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { chains } from 'utils/wagmi'
import { useTranslation } from '@pancakeswap/localization'
import { useMemo } from 'react'
import { useHistory } from 'contexts/HistoryContext'
import { useMenuItems } from 'components/Menu/hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem } from 'components/Menu/utils'
import { useRouter } from 'next/router'
import useAuth from 'hooks/useAuth'

export function PageNetworkSupportModal() {
    const { t } = useTranslation()
    const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
    const switchNetworkLocal = useSwitchNetworkLocal()
    const { chainId, isConnected, isWrongNetwork } = useActiveWeb3React()
    const { logout } = useAuth()

    const foundChain = useMemo(() => chains.find((c) => c.id === chainId), [chainId])
    const historyManager = useHistory()

    const lastValidPath = historyManager?.history?.find((h) => ['/swap', '/liquidity'].includes(h))

    const menuItems = useMenuItems()
    const { pathname, push } = useRouter()

    const { title, image } = useMemo(() => {
        const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
        const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
        return {
            title: activeSubMenuItem?.disabled ? activeSubMenuItem?.label : activeMenuItem?.label,
            image: activeSubMenuItem?.image || activeMenuItem?.image,
        }
    }, [menuItems, pathname])
    function handleClick() {
        logout().then(() => {
            push('/')
            switchNetworkLocal(ChainId.ONUS)
        })
    }
    return (
        <CsModal title={title || t('Kiểm tra mạng của bạn')} onDismiss={handleClick} headerBackground="gradientCardHeader">
            <Grid style={{ gap: '16px' }}>
                <Text>{t('Hiện tại trang này chỉ được hỗ trợ ở Onus Chain')}</Text>
                <Message variant="warning">
                    <MessageText>{t('Vui lòng chuyển mạng của bạn để tiếp tục.')}</MessageText>
                </Message>
                {canSwitch ? (
                    <Button
                        variant={foundChain && lastValidPath ? 'secondary' : 'primary'}
                        isLoading={isLoading}
                        onClick={() => isWrongNetwork ? switchNetworkLocal(ChainId.ONUS) : switchNetworkAsync(ChainId.ONUS)}
                    >
                        {t('Chuyển sang %chain%', { chain: 'Onus Chain' })}
                    </Button>
                ) : (
                    <Message variant="danger">
                        <MessageText>{t('Vui lòng chuyển mạng của bạn để tiếp tục.')}</MessageText>
                    </Message>
                )}
                {isConnected && (
                    <Button
                        variant="secondary"
                        onClick={() => logout().then(() => { push('/') })}
                    >
                        {t('Ngắt kết nối ví')}
                    </Button>
                )}
            </Grid>
        </CsModal>
    )
}

const CsModal = styled(Modal)`
    width: 100%;
    max-width: 450px;
    ${Heading} {
        font-weight: 700;
        font-size: 22px;
    }
    overflow-y: hidden;
`
