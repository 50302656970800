import { scales, variants } from "./types";

export const scaleVariants = {
    [scales.MD]: {
        height: "48px",
        padding: "0 24px",
    },
    [scales.SM]: {
        height: "38px",
        padding: "0 16px",
    },
    [scales.XS]: {
        height: "20px",
        fontSize: "12px",
        padding: "0 8px",
    },
};

export const styleVariants = {
    [variants.PRIMARY]: {
        backgroundColor: "primary",
        color: "white",
    },
    [variants.SECONDARY]: {
        backgroundColor: "transparent",
        border: "2px solid",
        borderColor: "primary",
        boxShadow: "none",
        color: "primary",
        ":disabled": {
            backgroundColor: "text",
        },
        ":hover": {
            backgroundColor: "primary",
            color: "white",
        },
    },
    [variants.TERTIARY]: {
        backgroundColor: "tertiary",
        boxShadow: "none",
        color: "text",
    },
    [variants.SUBTLE]: {
        backgroundColor: "textSubtle",
        color: "backgroundAlt",
    },
    [variants.DANGER]: {
        backgroundColor: "primaryBright",
        color: "white",
    },
    [variants.SUCCESS]: {
        backgroundColor: "success",
        color: "white",
    },
    [variants.TEXT]: {
        backgroundColor: "transparent",
        color: "text",
        boxShadow: "none",
        padding: "0px",
        ":disabled": {
            backgroundColor: "transparent",
        },
    },
    [variants.LIGHT]: {
        backgroundColor: "input",
        color: "textSubtle",
        boxShadow: "none",
    },
};
