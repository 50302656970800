import { ModalProvider, light, dark, UIKitProvider } from '@pancakeswap/uikit'
import { Provider } from 'react-redux'
import { SWRConfig } from 'swr'
import { LanguageProvider } from '@pancakeswap/localization'
import { fetchStatusMiddleware } from 'hooks/useSWRContract'
import { Store } from '@reduxjs/toolkit'
import { ThemeProvider as NextThemeProvider } from 'next-themes'
import { WagmiProvider } from '@pancakeswap/wagmi'
import { client } from 'utils/wagmi'
import { HistoryManagerProvider } from 'contexts/HistoryContext'
import { AuthProvider } from 'contexts/AuthContext'

const StyledUIKitProvider: React.FC<React.PropsWithChildren> = ({ children, ...props }) => {
    return (
        <UIKitProvider theme={light} {...props}>
            {children}
        </UIKitProvider>
    )
}

const Providers: React.FC<React.PropsWithChildren<{ store: Store; children: React.ReactNode }>> = ({
    children,
    store,
}) => {
    return (
        <WagmiProvider client={client}>
            <Provider store={store}>
                <NextThemeProvider>
                    <StyledUIKitProvider>
                        <LanguageProvider>
                            <SWRConfig
                                value={{
                                    use: [fetchStatusMiddleware],
                                }}
                            >
                                <AuthProvider>
                                    <HistoryManagerProvider>
                                        <ModalProvider>{children}</ModalProvider>
                                    </HistoryManagerProvider>
                                </AuthProvider>
                            </SWRConfig>
                        </LanguageProvider>
                    </StyledUIKitProvider>
                </NextThemeProvider>
            </Provider>
        </WagmiProvider>
    )
}

export default Providers
