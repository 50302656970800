import { ChainId, Token } from '@pancakeswap/sdk'

export const onusMainnetTokens = {
    busd: new Token(
        ChainId.ONUS,
        '0xdfB5E8a4AC08E46258A12AbE737bba5D8c452508',
        18,
        'BUSD',
        'BUSD Token',
        'https://www.paxos.com/busd/',
    ),
    usdt: new Token(
        ChainId.ONUS,
        '0xff276c6bca1F66Fd54a8915e830735D6ab0C7B09',
        18,
        'USDT',
        'USDT Token',
        'https://tether.to/',
    ),
    eth: new Token(
        ChainId.ONUS,
        '0xB4373ebB073A4DcbA47e567d075a9583Fa3C763e',
        18,
        'ETH',
        'ETH Token',
        'https://ethereum.org/en/',
    ),
    mia: new Token(
        ChainId.ONUS,
        '0x5Df107F23d3Ec5efA926B999Ce285A88955Ae56B',
        18,
        'MIA',
        'MIA Token',
        'https://miaswap.io/',
    ),
    runtogether: new Token(ChainId.ONUS, '0x9e3d30d7808C8E64dB360Abf2f32B44eB03F55d4', 18, 'RUN', 'RUN Token'),
    runtogetherBoxNft: new Token(
        ChainId.ONUS,
        '0x1291e12CAE4E140847Ac548D5ce79f82036DF3f5',
        0,
        'RTBN',
        'Run Together Box NFT',
        'https://runtogether.net/',
    ),
    vndc: new Token(ChainId.ONUS, '0xC1D3A18C32c42D5c033C2d4bfc151dB8fd2c9D81', 0, 'VNDC', 'ONUS-peg VNDC Token'),
    Demo0 : new Token(
        ChainId.ONUS,
        '0xCfEA0200aeEACf612c1495cBBB8B2929Ee2BCa6E',
        0,     
        'DTL',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo1 : new Token(
        ChainId.ONUS,
        '0x088c69e73693607733c3D1136b2A4CFC7A466680',
        0,     
        'DTL_TC',
        'Công ty cổ phần Deltalabs',
        '',
    ),
    Demo2 : new Token(
        ChainId.ONUS,
        '0x19f0d98973610c49C9700eaCa430C33835cBC457',
        0,     
        'TD-DTL',
        'Công ty cổ phần deltalabs',
        '',
    ),
    Demo3 : new Token(
        ChainId.ONUS,
        '0xfAdefA7c09A799A81285450b43c4439A28d5668e',
        0,     
        'TC-DTL',
        'Công ty cổ phần deltalabs',
        '',
    ),
    Demo4 : new Token(
        ChainId.ONUS,
        '0x92411962039F3dcA0Fb3aEE44FA212A8806ADfFA',
        0,     
        'DMM',
        'Công ty cổ phần deltalabs',
        '',
    ),
}
