import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 20 20" fill="none" {...props}>
            <path
                fill="none"
                d="M15.2248 5.98334H2.2915V14.3333C2.2915 15.7083 3.40817 16.8167 4.77484 16.8167H15.2165C16.5915 16.8167 17.6998 15.7 17.6998 14.3333V8.475C17.7082 7.1 16.5915 5.98334 15.2248 5.98334Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.4834 3.18335H11.7584C13.1751 3.18335 14.3251 4.44168 14.3251 5.98335"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="none"
                d="M2.2915 9.04999V4.90833"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="none"
                d="M9.5665 5.98335H3.57484C2.8665 5.98335 2.2915 5.35835 2.2915 4.58335C2.2915 3.80835 2.8665 3.18335 3.57484 3.18335H9.5665"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.7585 11.825C14.9932 11.825 15.1835 11.6347 15.1835 11.4C15.1835 11.1653 14.9932 10.975 14.7585 10.975C14.5238 10.975 14.3335 11.1653 14.3335 11.4C14.3335 11.6347 14.5238 11.825 14.7585 11.825Z"
                fill="white"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export default Icon;
